import React, { useCallback, useContext, useEffect, useState } from "react";
import FlexContainer from "../../../components/common/_flexContainer";
import Text from "../../../components/common/_text";
import styled from "styled-components";
import { InputField, InputFieldGridContainer } from "./InputField";
import { ReactComponent as EditIcon } from "../../../assets/images/workspace_settings_edit_icon.svg";
import { ReactComponent as SaveIcon } from "../../../assets/images/workspace_settings_save_icon.svg";
import axiosAdapter from "../../../utils";
import { SnackbarContext } from "../../../layouts/Context/snackBarContext";
import Divider from "../../../components/common/_divider";
import IconButton from "../../../components/common/_iconButton";
import { env } from "../../../env";
import { AuthContext } from "../../../AuthContext";

const EditIconStyled = styled(EditIcon)`
  width: 1.666rem;
  height: 1.666rem;
  cursor: pointer;
`;

const SaveIconStyled = styled(SaveIcon)`
  width: 1.666rem;
  height: 1.666rem;
  cursor: pointer;
`;

const getOrganizationDetails = async () => {
  const response = await axiosAdapter(
    "GET",
    env.REACT_APP_URL + "user-management/getOrganizationDetails",
  );
  return response.data;
};

const OrganisationDetailsSectionContents = () => {
  const { showMessage } = useContext(SnackbarContext);
  const { userRole } = useContext(AuthContext);

  const [organizationDetailsEditMode, setOrganizationDetailsEditMode] =
    useState(false);
  const [organisationDetails, setOrganisationDetails] = useState({
    orgUniqueId: "",
    organizationName: "",
  });

  useEffect(() => {
    (async () => {
      try {
        const response = await getOrganizationDetails();
        setOrganisationDetails(response.data);
      } catch (err) {
        console.log("Error: getOrganizationDetails", err);
        if (err.response?.data?.remarks) {
          showMessage(err.response.data.remarks);
        } else {
          showMessage(
            "Unable to get the organization details, please try again later",
          );
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refetchOrganizationDetails = useCallback(async () => {
    try {
      const response = await getOrganizationDetails();
      setOrganisationDetails(response.data);
    } catch (err) {
      console.log("Error: getOrganizationDetails", err);
      if (err.response?.data?.remarks) {
        showMessage(err.response.data.remarks);
      } else {
        showMessage(
          "Unable to get the organization details, please try again later",
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleOrganizationDetailsEditMode = async () => {
    if (!organizationDetailsEditMode) {
      setOrganizationDetailsEditMode(true);
    } else {
      try {
        await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "user-management/updateOrganizationDetails",
          {
            orgName: organisationDetails.organizationName,
          },
        );
        showMessage("Successfully updated the organisation details", "success");
        setOrganizationDetailsEditMode(false);
        refetchOrganizationDetails();
      } catch (err) {
        console.log("Error: ", err);
        if (err.response?.data?.remarks) {
          showMessage(err.response.data.remarks);
        } else {
          showMessage(
            "Unable to update the personal details, please try again later",
          );
        }
      }
    }
  };

  return (
    <FlexContainer $width="100%" $gap="1.666rem">
      <Text $fontWeight="bold" $fontSize="1.388rem">
        Organisation Details
      </Text>
      <IconButton
        $width="1.666rem"
        $height="1.666rem"
        $background="none"
        onClick={() => toggleOrganizationDetailsEditMode()}
        disabled={userRole !== "ADMIN"}
      >
        {!organizationDetailsEditMode ? <EditIconStyled /> : <SaveIconStyled />}
      </IconButton>
      <Divider />
      <InputFieldGridContainer
        $width="100%"
        $justifyContent="flex-start"
        $gap="1.527rem"
      >
        <InputField
          value={organisationDetails}
          setValue={setOrganisationDetails}
          labelText="Organization Name"
          fieldName="organizationName"
          inputFieldType="text"
          disabled={!organizationDetailsEditMode}
        />
        <InputField
          value={organisationDetails}
          setValue={setOrganisationDetails}
          labelText="Organisation ID"
          fieldName="orgUniqueId"
          inputFieldType="text"
          disabled={true}
          isCopyContentRequired={true}
        />
      </InputFieldGridContainer>
    </FlexContainer>
  );
};

export default OrganisationDetailsSectionContents;
