import styled from "styled-components";
import GenericModal from "../../components/common/_genericModal";
import CustomRow from "../../components/common/_customRow";
import Column from "../../components/common/_customColumn";
import { useContext, useState } from "react";
import {
  StyledButtonsecondary,
  StyledPrimaryButton2,
} from "../../components/common/_buttonNewOne";
import axiosAdapter from "../../utils";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import { env } from "../../env";

const Text = styled.p`
  font-family: "Articulat CF Medium";
  color: #f6f6f6;
  margin: 0;
  font-size: 1.666rem;
  font-size: bold;
`;

const CustomColumn = styled(Column)`
  margin-bottom: ${({ mb }) => (mb ? `${mb}rem` : "0rem")};
`;

const InputField = styled.input`
  font-family: "Articulat CF Medium";
  font-size: 1.111rem;
  line-height: 1.944rem;
  font-weight: 500;
  color: #f6f6f6;
  background-color: inherit;
  outline: none;
  border: none;
  width: 100%;
  caret-color: white;
  &::placeholder {
    color: #777777;
  }
`;

const InputFieldContainer = styled.div`
  padding: 0.625rem 1.111rem;
  width: 100%;
  border-radius: 0.555rem;
  border: none;
  background-color: #2f2f2f;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.069rem solid #b0b0b0;
`;

const vcNameMap = {
  github: "Github",
  gitlab: "Gitlab",
  bitbucket: "Bit Bucket",
};

export const VersionControlDisconnectModal = ({
  isOpen,
  onClose,
  vcProvider,
  refetchVcData,
}) => {
  const { showMessage } = useContext(SnackbarContext);

  const [confirmationText, setConfirmationText] = useState("");
  const [isApiLoading, setApiLoading] = useState(false);

  const disconnectClick = async () => {
    setApiLoading(true);
    try {
      await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "version-control/removeVcProvider",
        {
          vcProvider,
        },
      );
      showMessage(
        `Successfully disconnected ${vcNameMap[vcProvider]}`,
        "success",
      );
      setApiLoading(false);
      onClose();
      refetchVcData();
    } catch (err) {
      setApiLoading(false);
      console.log("Error: removeVcProvider", err);
      if (err.response?.data?.remarks) {
        showMessage(err.response.data.remarks);
      } else {
        showMessage(
          "Failed to disconnect the provider, please try again later",
        );
      }
    }
  };

  return (
    <GenericModal
      title={`Are you sure you want to disconnect ${vcNameMap[vcProvider]}?`}
      show={isOpen}
      onClose={onClose}
      disableCloseOnOutsideClick={isApiLoading}
    >
      <CustomRow>
        <CustomColumn mb={1.666}>
          <Text>Type “Disconnect” to confirm</Text>
        </CustomColumn>
        <CustomColumn mb={2.777}>
          <InputFieldContainer>
            <InputField
              placeholder="Enter 'Disconnect'"
              value={confirmationText}
              onChange={(e) => setConfirmationText(e.target.value)}
              readOnly={isApiLoading}
            />
          </InputFieldContainer>
        </CustomColumn>
        <CustomColumn
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <StyledButtonsecondary
            width="13.888rem"
            style={{ marginRight: "1.111rem" }}
            onClick={onClose}
            disabled={isApiLoading}
          >
            Cancel
          </StyledButtonsecondary>
          <StyledPrimaryButton2
            width="13.888rem"
            onClick={() => disconnectClick()}
            disabled={isApiLoading || confirmationText !== "Disconnect"}
          >
            Disconnect
          </StyledPrimaryButton2>
        </CustomColumn>
      </CustomRow>
    </GenericModal>
  );
};

// TODO: Call the applications list API to show the applications list for the VC Provider of the User
