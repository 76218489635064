import { useState, useEffect, useContext } from "react";
import axiosAdapter from "../../utils";
import { env } from "../../env";
import styled from "styled-components";
import Search from "../../assets/images/search_FILL0_wght400_GRAD0_opsz24.svg";
import { Col, Row } from "reactstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import PreBuiltCard from "../../components/PreBuiltConnectors/PreBuiltConnectorUpdated";
import CreateNewConnector from "./CreateNewConnector";
import loadingAnimation from "../../assets/images/Loading Animation.gif";
import CustomConnectorCard from "../ConnectorsPage/CustomConnectorCard";
import CustomRow from "../../components/common/_customRow";
import Column from "../../components/common/_customColumn";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import { useCallback } from "react";
import { createContext } from "react";
import { useMemo } from "react";

const WsConnectorSearchArea = styled.div`
  width: 22.4rem;
  height: 2.8rem;
  background-color: #272727;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 0.8rem;
`;

const ParentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 95vh;
`;

const AnimationContainer = styled.div`
  position: relative;
  width: 240px;
`;

const ScrollAnimationBar = styled.div`
  width: 100%;
  height: 4px;
  background-color: #2f2f2f;
  border-radius: 2px;
  box-shadow: -8px 4px 9px 0px #2f2f2f;
  position: absolute;
  bottom: 0;
  left: 0;
`;

const LoadingAnimation = styled.img`
  width: 100%;
  bottom: 4px;
`;

//Context created to manage all States and Functions
export const ConnectorsContext = createContext({
  refetchCustomConnectors: () => {},
});

const WorkSpaceConnectorsMainPage = () => {
  const { showMessage } = useContext(SnackbarContext);
  const history = useHistory();

  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsloading] = useState(false);
  const [filteredPreConnectors, setFilteredPreConnectors] = useState([]);
  const [allConnectors, setAllConnectors] = useState([]);
  const [allCustomConnectors, setAllCustomConnectors] = useState([]);
  const [filteredCustomConnectorList, setFilteredCustomConnectorList] =
    useState([]);

  const fetchCustomConnectors = useCallback(async () => {
    let selectedWorkspaceId = parseInt(
      localStorage.getItem("selectedWorkspaceId") || 0,
    );
    let strigifieddata = JSON.stringify({
      workspaceId: selectedWorkspaceId,
    });
    try {
      let fetchCustomConnectors = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/getCustomConnectors",
        strigifieddata,
      );
      return fetchCustomConnectors.data.data;
    } catch (err) {
      console.log("err: getCustomConnectors", err);
      if (err.response?.data?.remarks) {
        showMessage(err.response.data.remarks);
      } else {
        showMessage("Unable to fetch custom connectors !!!");
      }
      return [];
    }
  }, [showMessage]);

  const fetchManufacturers = useCallback(async () => {
    try {
      let fetchedManufacturers = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "admin/getAllTelematicsManufacturers",
        JSON.stringify({
          workspaceId: parseInt(
            localStorage.getItem("selectedWorkspaceId") || 0,
          ),
        }),
      );
      return fetchedManufacturers.data.data;
    } catch (err) {
      console.log("Error: getAllTelematicsManufacturers", err);
      if (err.response?.data?.remarks) {
        showMessage(err.response.data.remarks);
      } else {
        showMessage("Unable to fetch manufacturers !!!");
      }
      return [];
    }
  }, [showMessage]);

  const fetchStreamConnectors = useCallback(async () => {
    try {
      let listStreamConnectors = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "admin/listAllMasterStreamConnectors",
        JSON.stringify({
          workspaceId: parseInt(
            localStorage.getItem("selectedWorkspaceId") || 0,
          ),
        }),
      );
      if (listStreamConnectors && listStreamConnectors.data) {
        return listStreamConnectors.data;
      }
    } catch (err) {
      console.log("Error: listAllMasterStreamConnectors", err);
      if (err.response?.data?.remarks) {
        showMessage(err.response.data.remarks);
      } else {
        showMessage("Unable to fetch stream connectors !!!");
      }
      return [];
    }
  }, [showMessage]);

  const fetchStoreConnectors = useCallback(async () => {
    try {
      let listStoreConnectors = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "admin/listAllMasterStoreConnectors",
        JSON.stringify({
          workspaceId: parseInt(
            localStorage.getItem("selectedWorkspaceId") || 0,
          ),
        }),
      );
      if (listStoreConnectors && listStoreConnectors.data) {
        return listStoreConnectors.data;
      }
    } catch (err) {
      console.log("Error: listAllMasterStoreConnectors", err);
      if (err.response?.data?.remarks) {
        showMessage(err.response.data.remarks);
      } else {
        showMessage("Unable to fetch store connectors !!!");
      }
      return [];
    }
  }, [showMessage]);

  const getAllConnectors = useCallback(async () => {
    // Set the loading state to true
    setIsloading(true);
    try {
      // Parallelize the API calls
      const [
        streamConnectors,
        storeConnectors,
        manufacturers,
        customConnectorsList,
      ] = await Promise.all([
        fetchStreamConnectors(),
        fetchStoreConnectors(),
        fetchManufacturers(),
        fetchCustomConnectors(),
      ]);

      // Process the stream connectors
      const filteredStreamConnectors = streamConnectors.map((obj) => ({
        id: obj.id,
        name: obj.name,
        icon_link: obj.icon_link,
        description: obj.description,
        connectionType: obj.connectionType,
        category: obj.category,
        available: Boolean(obj.available),
        isClickable: Boolean(obj.isClickable),
      }));

      // Process the store connectors
      const filteredStoreConnectors = storeConnectors.map((obj) => ({
        id: obj.id,
        name: obj.name,
        icon_link: obj.icon_link,
        description: obj.description,
        connectionType: obj.connectionType,
        category: obj.category,
        available: Boolean(obj.available),
        isClickable: Boolean(obj.isClickable),
      }));

      // Process the manufacturers
      const filteredManufacturers = manufacturers.map((obj) => ({
        available: true,
        ...obj,
      }));

      // Merge all 3 types of connectors
      const listAllConnectors = [
        ...filteredManufacturers,
        ...filteredStreamConnectors,
        ...filteredStoreConnectors,
      ];

      // Set state values
      setAllCustomConnectors(customConnectorsList);
      setFilteredCustomConnectorList(customConnectorsList);
      setFilteredPreConnectors(listAllConnectors);
      setAllConnectors(listAllConnectors);
    } catch (error) {
      console.error("Error fetching connectors:", error);
    } finally {
      // Set loading state to false
      setIsloading(false);
    }
  }, [
    fetchCustomConnectors,
    fetchManufacturers,
    fetchStoreConnectors,
    fetchStreamConnectors,
  ]);

  useEffect(() => {
    getAllConnectors();
  }, [getAllConnectors]);

  const refetchCustomConnectors = useCallback(async () => {
    const customConnectorsList = await fetchCustomConnectors();
    setAllCustomConnectors(customConnectorsList);
    setFilteredCustomConnectorList(customConnectorsList);
  }, [fetchCustomConnectors]);

  // Function to filter the connectors based on the search field
  const handleSearchTextChange = useCallback(
    (text) => {
      setSearchValue(text);
      let filteredPreValues = allConnectors.filter((connector) =>
        connector.name.toLowerCase().includes(text.toLowerCase()),
      );
      let filteredCustomValues = allCustomConnectors.filter((connector) =>
        connector.name.toLowerCase().includes(text.toLowerCase()),
      );
      setFilteredCustomConnectorList(filteredCustomValues);
      setFilteredPreConnectors(filteredPreValues);
    },
    [allConnectors, allCustomConnectors],
  );

  // Function executed when a Pre Built connector is clicked
  const handlePreConnectorClick = useCallback(
    (
      selectedConnectorName,
      selectedConnectorType,
      connectorId,
      category,
      description,
    ) => {
      history.push("/connectorConfiguration", {
        isRedirected: true,
        selectedConnectorName,
        selectedConnectorType,
        selectedConnectorId: connectorId,
        selectedConnectorCategory: category,
        selectedConnectorDescription: description,
      });
    },
    [history],
  );

  const handleCustomConnectorCardClick = useCallback(
    (
      customConnectorId,
      customConnectorName,
      connectorDescription,
      connectorVersion,
    ) => {
      history.push("/connectorConfiguration", {
        isRedirected: true,
        selectedConnectorName: customConnectorName,
        selectedConnectorId: customConnectorId,
        selectedConnectorDescription: connectorDescription,
        selectedConnectorType: "Custom Connector",
        selectedConnectorCategory: "Application",
        customConnectorVersion: connectorVersion,
      });
    },
    [history],
  );

  // If there is no workspace id in local storage, redirect to landing page
  if (!Boolean(localStorage.getItem("selectedWorkspaceId") || 0)) {
    history.push("/");
  }

  const sharedData = useMemo(() => {
    return {
      refetchCustomConnectors,
    };
  }, [refetchCustomConnectors]);

  return (
    <ConnectorsContext.Provider value={sharedData}>
      <div className="wsConnectorMainContainer makeOverFlow">
        <>
          <div className="wsConnectorNavBarArea">
            <Row
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Col xs={3}>
                <div className="wsConnectorNavBarLeftArea">
                  <div
                    className="wsConnectorNavBarTitle"
                    style={{ marginTop: "1.67rem" }}
                  >
                    <p>Your Connectors</p>
                  </div>
                </div>
              </Col>

              <Col>
                <div className="wsConnectorNavBarRightArea">
                  <WsConnectorSearchArea>
                    <div className="wsConnectorSearchIcon">
                      <img
                        src={Search}
                        alt=""
                        style={{ width: "1.67rem", height: "1.67rem" }}
                      />
                    </div>
                    <div
                      className="wsConnectorSearchTextArea"
                      style={{ marginLeft: "1.112rem" }}
                    >
                      <input
                        className="wsConnectorSearchTextInput"
                        type="text"
                        value={searchValue}
                        onChange={(e) => {
                          handleSearchTextChange(e.target.value);
                        }}
                        placeholder="Search for Connectors"
                      />
                    </div>
                  </WsConnectorSearchArea>
                </div>
              </Col>
            </Row>
          </div>
          {isLoading ? (
            <ParentContainer>
              <AnimationContainer>
                <ScrollAnimationBar />
                <LoadingAnimation
                  src={loadingAnimation}
                  alt="Loading Animation"
                />
              </AnimationContainer>
            </ParentContainer>
          ) : filteredPreConnectors.length ? (
            <div>
              <>
                <CustomRow
                  style={{
                    gap: "0.84rem",
                    justifyContent: "normal",
                    alignItems: "stretch",
                    marginBottom: "70px",
                    marginTop: "0.55rem",
                  }}
                >
                  {filteredCustomConnectorList.map((eachObj) => (
                    <Column key={eachObj.id} xs={2.9}>
                      <CustomConnectorCard
                        connectorName={eachObj.connectorName}
                        publisher={eachObj.latestPublishedBy}
                        description={eachObj.latestDescription}
                        connectorVersion={eachObj.latestVersion}
                        iconFile={eachObj.latestIconFile}
                        connectorId={eachObj.id}
                        handleCustomConnectorCardClick={
                          handleCustomConnectorCardClick
                        }
                        isClickable={eachObj.isClickable}
                      />
                    </Column>
                  ))}
                  <Column xs={2.9}>
                    <CreateNewConnector />
                  </Column>
                </CustomRow>
                <div>
                  <CustomRow>
                    <Column xs={12}>
                      <div className="wsConnectorNavBarLeftArea">
                        <div
                          className="wsConnectorNavBarTitle"
                          tour-guide="explain_connectors"
                        >
                          <p>Pre-Built Connectors</p>
                        </div>
                      </div>
                    </Column>
                  </CustomRow>
                  <div
                    style={{
                      gap: "0.8rem",
                      display: "flex",
                      flexWrap: "wrap",
                      marginTop: "2.05rem",
                    }}
                  >
                    {filteredPreConnectors
                      .filter((eachObj) => eachObj.available)
                      .map((eachObj, index) => (
                        <Column xs={2.911} md={2.911} lg={2.911} key={index}>
                          <PreBuiltCard
                            title={eachObj.name}
                            description={eachObj.description || ""}
                            category={eachObj.category}
                            type={eachObj.connectionType}
                            id={eachObj.id}
                            icon={eachObj.icon_link}
                            handlePreConnectorClick={handlePreConnectorClick}
                            isClickable={eachObj.isClickable}
                          />
                        </Column>
                      ))}
                  </div>
                </div>
              </>
            </div>
          ) : (
            <div className="wsNoConnectorsFound">
              <p>No Connectors Found</p>
            </div>
          )}
        </>
      </div>
    </ConnectorsContext.Provider>
  );
};

export default WorkSpaceConnectorsMainPage;
